<template>
    <div class="main" style="display: flex;align-items: center;justify-content: center;flex-direction: column">
        <div style="width: 380px;height:100px;background-color: #fff60d;border-radius:15px;display: flex;align-items: center;justify-content: center;color: red;font-size: 32px">
            권한이 없습니다
        </div>
        <button @click="goback" style="padding: 5px 10px;border-radius: 5px;;margin-top: 10px">돌아가기</button>
    </div>

</template>

<script>

    import OnlineUserComp from "../../components/administrator/OnlineUserComp";
    import TodayStatisticComp from "../../components/administrator/TodayStatisticComp";
    import ManagerTopbarComp from "../../components/administrator/ManagerTopbarComp";
    import {updateGroup} from "../../network/manager/groupRequest";
    export default {
        name: "Denied",
        components: {ManagerTopbarComp, TodayStatisticComp, OnlineUserComp},
        data() {
            return {

            }
        },
        props: {
            pgame: {
                type: Object,
                default() {
                    return {
                        startDate: '',
                        endDate: '',
                        pageNum: 1,
                        pageSize: 50,
                        pageTotal: 0,
                        pickerOptions: {
                            shortcuts: [{
                                text: '오늘',
                                onClick(picker) {
                                    picker.$emit('pick', new Date());
                                }
                            }, {
                                text: '어제',
                                onClick(picker) {
                                    const date = new Date();
                                    date.setTime(date.getTime() - 3600 * 1000 * 24);
                                    picker.$emit('pick', date);
                                }
                            }, {
                                text: '일주일전',
                                onClick(picker) {
                                    const date = new Date();
                                    date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
                                    picker.$emit('pick', date);
                                }
                            }, {
                                text: '15일전',
                                onClick(picker) {
                                    const date = new Date();
                                    date.setTime(date.getTime() - 3600 * 1000 * 24 * 14);
                                    picker.$emit('pick', date);
                                }
                            }, {
                                text: '30일전',
                                onClick(picker) {
                                    const date = new Date();
                                    date.setTime(date.getTime() - 3600 * 1000 * 24 * 30);
                                    picker.$emit('pick', date);
                                }
                            }]
                        },
                    };
                }
            },

        },
        methods: {
            goback() {
                this.$router.go(-1)
            },
        },
        created(){

        },
        watch: {

        }
    }
</script>

<style scoped>
    @import url("../../assets/css/adminsitrator.css");


</style>